@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .react-datepicker__input-container input {
    @apply block w-full text-base p-2 md:text-lg bg-gray-900 border-2 border-gray-700 rounded shadow-sm;
  }
  .tooltip {
    @apply invisible absolute;
  }

  .has-tooltip:hover .tooltip {
    @apply visible z-50;
  }
}
.react-calendar {
  background: #292524 !important;
  border: 1px solid #a0a096;
}
.react-calendar__tile--now {
  background: #161616 !important;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #302b2a !important;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #302b2a !important;
}
.react-calendar__navigation button[disabled] {
  background-color: #302b2a !important;
  color: #a0a096 !important;
  cursor: not-allowed !important;
}
.react-calendar__tile:disabled {
  background-color: #302b2a !important;
  color: #7e7e7a !important;
  cursor: not-allowed !important;
}
.react-date-picker__clear-button svg,
.react-date-picker__calendar-button svg {
  color: white;
  stroke: aliceblue;
}
.react-date-picker__wrapper {
  border-width: 2px !important;
  border-radius: 0.4rem !important;
}
