@import url("https://fonts.googleapis.com/css?family=Exo");
/**
* The geosuggest module
* NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
*/
.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  width: 100%;
  /* margin: 1em auto; */
  text-align: left; }

.geosuggest__input {
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  padding: 0.5em 1em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
  background: #333;
  color: #e58f38; }

.geosuggest__input:focus {
  border-color: #e58f38;
  box-shadow: 0 0 0 transparent; }

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #333;
  border: 2px solid #e58f38;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s; }

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0; }

/**
  * A geosuggest item
  */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer; }

.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #e58f38; }

.geosuggest__item--active {
  background: #a25c15;
  color: #fff; }

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc; }

.geosuggest__item__matched-text {
  font-weight: bold; }

.ui.table {
  background: #2d2d2d;
  color: #fff;
  font-size: 1em;
  margin-top: 0;
  border-radius: 0; }
  .ui.table .hidden {
    color: grey !important; }
  .ui.table .highLight {
    color: #b4b4b4;
    font-size: 1.1rem; }
  .ui.table .ui.label {
    margin-bottom: 0.2rem; }
  .ui.table .sectionRow {
    border-bottom: 2px solid #f3620e; }

.ui.attached.table {
  border-color: #b4b4b4; }

.ui.table thead th {
  background: #333;
  color: #b4b4b4; }

.ui.selectable.table tbody tr:hover,
.ui.table tbody tr td.selectable:hover {
  color: #b4b4b4 !important;
  cursor: pointer; }

.ui.table td.warning,
.ui.table tr.warning {
  background: rgba(9, 154, 251, 0.102) !important;
  background: #333;
  color: white !important; }

.ui.table td.negative,
.ui.table tr.negative {
  background: rgba(241, 6, 6, 0.102) !important;
  color: #b4b4b4 !important; }

.ui.table td.positive,
.ui.table tr.positive {
  background: #aef30d0f !important;
  color: #fff !important; }

.headerRow {
  background: #222 !important; }
  .headerRow th {
    background: #222 !important; }

.detailRow {
  background: #3c3c3c; }

.headerRow.title {
  font-size: 1.4rem; }

.fieldTitle {
  padding-left: 3rem !important; }

.ui.table tr td {
  border-top: 1px solid black !important; }

.ui.table.filter td:hover {
  cursor: pointer; }

.ui.table.filter {
  background: #333; }

.ui.table tr:hover td.hidden {
  pointer-events: none;
  color: #847979; }

.ui.celled.table tr td,
.ui.celled.table tr th {
  border-left: 1px solid rgba(123, 123, 123, 0.4); }

.ui.attached.table {
  border: 1px solid #222; }

.locTable .title {
  letter-spacing: 0.3rem;
  font-family: Exo, Roboto, "Arial Narrow Bold", sans-serif; }

.locTable .borderTop {
  border-top: 2px solid #d66b40; }

.locTable .borderBottom {
  border-bottom: 2px solid #fff; }

.ui.table.settingTable {
  font-size: 1.2rem; }

.ui.table.compTable tr td {
  border-top: none !important; }

.ui.table.calloutList .headerCell .ui.header {
  color: #f3620e; }

.centerMenu {
  justify-content: center; }

.ui.menu.main-menu {
  border-bottom: 1px solid #222;
  background: #333;
  box-shadow: 1px 1px 8px #232020 !important; }

.ui.vertical.menu {
  min-width: auto;
  width: auto; }

.ui.menu {
  background: #333;
  font-size: 1.2rem;
  font-family: "Exo", sans-serif;
  margin: 0;
  border: none;
  box-shadow: none;
  border-radius: 0; }
  .ui.menu .main-menu,
  .ui.menu .footer-menu {
    width: 100%;
    background: #333;
    flex: 1;
    color: #fff;
    font-size: 1.4rem; }
    .ui.menu .main-menu svg,
    .ui.menu .footer-menu svg {
      color: white; }
    .ui.menu .main-menu .item,
    .ui.menu .footer-menu .item {
      color: #fff; }
      .ui.menu .main-menu .item svg,
      .ui.menu .footer-menu .item svg {
        margin-right: 12rem;
        color: white; }
  .ui.menu .item {
    color: #fff;
    padding: 1rem; }
  .ui.menu a.item:hover {
    color: #41b6e6 !important; }
  .ui.menu .item.disabled,
  .ui.menu .item.disabled:hover {
    cursor: default !important;
    background-color: transparent !important;
    color: rgba(103, 94, 94, 0.8) !important; }
  .ui.menu .active.item {
    color: #d66b40 !important; }
  .ui.menu .active.item:hover,
  .ui.menu .ui.vertical.menu .active.item:hover {
    color: #41b6e6; }

.ui.menu .item.disabled,
.ui.menu .item.disabled:hover {
  color: #e8edee !important; }

.ui.link.menu .item:hover,
.ui.menu .dropdown.item:hover,
.ui.menu .link.item:hover,
.ui.menu a.item:hover {
  color: #41b6e6; }

.ui.menu .dropdown.item .menu {
  min-width: max-content !important;
  width: calc(100% + 10px) !important; }

.ui.menu.sub-menu {
  margin-top: 0;
  border-bottom: 1px solid rgba(199, 199, 199, 0.63); }

.settings-menu .primary {
  color: #fbbd08; }

.ui.menu.subMenu {
  background: #333;
  border-bottom: 1px solid #222; }
  .ui.menu.subMenu .primary {
    color: #b4b4b4; }

.ui.footer-menu {
  border-top: 1px solid rgba(199, 199, 199, 0.342);
  box-shadow: 1px 1px 8px #232020 !important; }

.ui.attached.menu:not(.tabular) {
  border-radius: 0;
  border: 1px solid #222; }

.ui.secondary.pointing.menu .active.item {
  color: #41b6e6 !important; }

.ui.secondary.pointing.menu .item:hover {
  color: #41b6e6 !important; }

.ui.stackable.innerMenu.menu {
  border-bottom: 4px solid #222; }

.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"],
.ui.form textarea,
.ui.input > input,
.ui.selection.dropdown {
  background: #333;
  color: #fff; }

textarea {
  background: #333;
  color: #fff;
  width: 100%;
  border-radius: 0.28rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical; }

.ui.form input:not([type]):focus,
.ui.form input[type="date"]:focus,
.ui.form input[type="datetime-local"]:focus,
.ui.form input[type="email"]:focus,
.ui.form input[type="file"]:focus,
.ui.form input[type="number"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="search"]:focus,
.ui.form input[type="tel"]:focus,
.ui.form input[type="text"]:focus,
.ui.form input[type="time"]:focus,
.ui.form input[type="url"]:focus,
.ui.form textarea:focus {
  background: #09265c;
  color: #fff; }

.ui.form .field > label,
.ui.toggle.checkbox .box,
.ui.toggle.checkbox label,
.ui.checkbox input:focus ~ label,
.ui.toggle.checkbox input:focus:checked ~ .box,
.ui.toggle.checkbox input:focus:checked ~ label,
.ui.checkbox label,
.ui.checkbox + label {
  color: #fff !important; }

.locForm {
  background: #222 !important;
  border-radius: 1rem !important; }

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #21ba45 !important; }

.ui.selection.visible.dropdown > .text:not(.default) {
  color: #fff; }

.ui.toggle.checkbox input:checked ~ .box,
.ui.toggle.checkbox input:checked ~ label {
  color: #21ba45 !important; }

.mapHolder {
  height: 100%; }

.mapBar {
  position: absolute;
  top: 100px;
  /* left: 20px; */
  right: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  flex-direction: column;
  padding: 0.4rem;
  border-radius: 1rem; }

.mapBar .button {
  margin: 1rem 0 0; }

.calendarHolder {
  min-height: 60vh;
  height: 100%;
  padding-bottom: 2rem !important; }
  .calendarHolder span.key {
    padding: 1rem;
    margin-top: 0.5rem; }
  .calendarHolder .rbc-calendar {
    margin-bottom: 0.5rem; }

.rbc-toolbar-label {
  font-size: 1.6rem; }

.rbc-off-range-bg {
  background: #4c4b4b; }

.rbc-today {
  background: #5e7c7c; }

.rbc-toolbar button {
  color: #d66b40; }

.rbc-show-more {
  background: #4c4b4b;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem; }

a.rbc-show-more {
  color: #d66b40;
  font-size: 1rem; }

.rbc-day-slot .rbc-time-slot {
  border-top: 0.01rem solid #848484; }

.navbar {
  display: flex;
  background-color: #2d2d2d;
  justify-content: space-between; }
  .navbar > div {
    width: 33.333%;
    vertical-align: middle; }
  .navbar .ui.header {
    padding-top: 1.5rem;
    color: #fff; }
  .navbar .ui.button {
    font-size: 1.8rem;
    padding-left: 1.2rem; }
    .navbar .ui.button i.icon {
      color: #fff; }
  .navbar .ui.button:hover,
  .navbar .ui.button:active,
  .navbar .ui.button:focus {
    background-color: #2d2d2d !important; }

aside {
  overflow-x: hidden;
  width: 0;
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  background-color: #2d2d2d;
  transition: width 0.3s ease-in-out; }
  aside .ui.vertical.menu {
    background-color: #2d2d2d;
    padding: 50px 0 0 0; }
    aside .ui.vertical.menu .item {
      display: flex;
      vertical-align: middle;
      align-items: center;
      color: #d3d3d3;
      padding: 2rem 0 2rem 1rem;
      font-weight: 400;
      transition: transform 1s;
      cursor: pointer;
      font-size: 1.1rem; }
      aside .ui.vertical.menu .item i.icon {
        width: 1.18em;
        float: left;
        margin: 0;
        font-size: 2rem;
        margin-right: 2.2rem; }
      aside .ui.vertical.menu .item svg {
        font-size: 2rem;
        margin-right: 2.2rem; }
    aside .ui.vertical.menu .item:hover {
      transform: translateX(5px);
      color: #f3620e !important;
      border-left: 5px solid #f3620e; }
    aside .ui.vertical.menu .item.active {
      color: #f3620e !important; }

.drawerMin {
  width: 70px; }

.drawerOpen {
  width: 200px; }

.main {
  position: absolute;
  top: 70px;
  padding: 1em;
  margin-left: 0;
  bottom: 0;
  overflow-y: auto;
  transition: margin-left 0.3s ease-in-out; }

.mainMin {
  margin-left: 70px;
  transition: margin-left 0.3s ease-in-out; }

.mainOpen {
  margin-left: 200px;
  transition: margin-left 0.3s ease-in-out; }

@media only screen and (max-width: 1024px) {
  .ui.container {
    /* margin-left: 1em!important; */
    /* margin-right: 1em!important; */ }
  .ui.button {
    padding: 0.5rem !important; }
  .ui.menu .item {
    padding: 2vh 1.4vw; }
  .ui.statistic > .value,
  .ui.statistics .statistic > .value {
    font-size: 2rem !important; }
  .vehicleData .ui.header {
    text-align: center; }
    .vehicleData .ui.header span {
      text-align: center;
      display: contents; }
      .vehicleData .ui.header span::before {
        content: "\A";
        white-space: pre; }
    .vehicleData .ui.header .ui.button {
      width: 100%; } }

@media only screen and (max-width: 768px) {
  .ui.grid .ui.stackable.grid,
  .ui.segment:not(.vertical) .ui.stackable.page.grid {
    margin-left: 0rem !important;
    margin-right: 0rem !important; }
  .ui.grid > .stackable.stackable.row > .column,
  .ui.stackable.grid > .column.grid > .column,
  .ui.stackable.grid > .column.row > .column,
  .ui.stackable.grid > .column:not(.row),
  .ui.stackable.grid > .row > .column,
  .ui.stackable.grid > .row > .wide.column,
  .ui.stackable.grid > .wide.column {
    padding: 0 !important; }
  .page-holder {
    padding: 0 !important; }
  .reqBox .ui.header {
    font-size: 1rem; }
  h2.ui.header {
    font-size: 1.2rem; }
  .ui.menu .item,
  .ui.secondary.pointing.menu .item {
    padding: 1rem; }
  .userData .ui.header span {
    padding: 1px 8px; }
    .userData .ui.header span::before {
      content: "\A";
      white-space: pre; } }

body {
  background: #222;
  color: #fff; }

.mainMin {
  padding-bottom: 4rem; }

a {
  color: #b4b4b4;
  text-decoration: none; }
  a :hover {
    color: #fff; }

.w-full {
  width: 100% !important; }

.ui.header:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
  font-size: 1.1rem; }

.ui.segment.page-holder {
  margin: 0 0 2rem;
  padding: 0 0 4rem; }

.ui.container.page-holder {
  margin-bottom: 3rem; }

.selectable {
  cursor: pointer; }

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  background: rgba(148, 125, 125, 0.23); }

.bakl {
  background: #555; }

.ui.container .container {
  padding: 1rem; }

.subtle {
  color: rgba(199, 199, 199, 0.342) !important; }

.menuTitle {
  color: #fff !important; }

a:hover {
  color: white; }

.good1 {
  color: green !important; }

.flourish {
  color: #f3620e !important; }

.bad1 {
  color: red !important; }

.negative1 {
  background-color: rgba(255, 0, 0, 0.2) !important; }

.positive1 {
  background-color: rgba(0, 255, 85, 0.2) !important; }

.warning {
  background-color: rgba(228, 188, 9, 0.2) !important; }

.warning1 {
  background-color: rgba(228, 188, 9, 0.2) !important; }

.ui.black.label,
.ui.black.labels .label {
  background-color: #1b1c1d !important;
  border-color: #4f5152 !important;
  color: #fff !important;
  border: 0.03em solid; }

.ui.image {
  width: 100%; }

.no-pad {
  padding: 0 !important; }

.padded {
  padding: 1rem !important; }

.no-hpad {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.no-vpad {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.no-marg {
  margin: 0 !important; }

.no-border {
  border-width: 0 !important; }

.boxHeader {
  font-size: 1.3rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #6d6d6d;
  border-bottom: 1px solid #90440e; }

.borderRight {
  border-right: 1px solid #3c3737; }

.ui.button.leftBtn {
  text-align: left !important; }

.ui.attached.segment {
  border: 0; }

.ui.segment {
  background-color: #2b2b2b;
  box-shadow: 0 2px 5px 0 #101010;
  border: 1px solid #90440f; }

.ui.basic.segment.subtle {
  box-shadow: 0 1px 5px 0 rgba(16, 16, 16, 0.85);
  border: 0; }

.ui.horizontal.segments {
  background: none;
  border-radius: 0; }
  .ui.horizontal.segments .ui.header {
    letter-spacing: 0.3rem;
    font-family: Exo, Roboto, "Arial Narrow Bold", sans-serif;
    color: #b4b4b4; }
    .ui.horizontal.segments .ui.header span {
      color: #fff;
      float: right;
      font-family: "Exo", sans-serif; }
      .ui.horizontal.segments .ui.header span span {
        padding-left: 1rem; }
  .ui.horizontal.segments .formGroup {
    background: #222; }

.ui.grid.userCard .ui.header span {
  color: #fff;
  float: none;
  padding-left: 0.4rem;
  font-family: "Exo", sans-serif; }
  .ui.grid.userCard .ui.header span span {
    padding-left: 1rem; }

.ui.segment.tab {
  border-left: 0;
  border-bottom: 0 !important;
  border-right: 0; }

.ui.modal {
  background: #555;
  border: 2px solid #222; }
  .ui.modal .content {
    background: #222; }
  .ui.modal .header,
  .ui.modal .actions {
    color: #fff;
    background: #131212; }
  .ui.modal h3.ui.header {
    background: transparent !important; }

.ui.statistic > .label,
.ui.statistics .statistic > .label {
  color: #fff; }

.ui.statistic > .value,
.ui.statistics .statistic > .value {
  color: #d66b40; }

.ui.header {
  color: #b4b4b4;
  font-family: "Exo", sans-serif; }
  .ui.header .checkbox {
    float: right; }
  .ui.header span {
    color: #fff;
    float: right;
    font-family: "Exo", sans-serif; }
    .ui.header span span {
      padding-left: 1rem; }

.ui.header.withIcon > .icon {
  font-size: 1.2rem;
  padding-left: 1rem; }

.ui.segment .courseCard .ui.header {
  color: #b4b4b4;
  font-family: "Exo", sans-serif; }
  .ui.segment .courseCard .ui.header .checkbox {
    float: right; }
  .ui.segment .courseCard .ui.header span {
    color: #fff;
    float: right;
    font-family: "Exo", sans-serif; }
    .ui.segment .courseCard .ui.header span span {
      padding-left: 1rem; }

.ui.divider {
  color: #fff;
  font-size: 0.8rem; }
  .ui.divider > .icon {
    height: 2.5rem; }

.imageOverlay {
  height: 100%;
  position: absolute;
  font-size: 2rem;
  /* top: 10px; */
  color: #fc582f;
  text-align: center;
  z-index: 999;
  background: #3b3b3ba8;
  padding-top: 3rem;
  cursor: pointer;
  width: 100%; }
  .imageOverlay .ui.header {
    font-size: 1.4rem; }

.column.roleButtons {
  padding: 0.2rem 1.2rem !important; }
  .column.roleButtons .button {
    margin: 0.2rem; }

.listSpace {
  flex-direction: row;
  justify-content: space-around;
  display: flex !important; }

.github-picker {
  background: #555 !important;
  justify-content: center;
  border: 0 !important; }

.popupGrid {
  padding: 0 !important;
  background: #222; }

.vettingBox,
.ui.segment.reqBox {
  text-align: center;
  padding: 1rem;
  margin-bottom: 1.5rem !important; }
  .vettingBox .ui.header,
  .ui.segment.reqBox .ui.header {
    letter-spacing: 0.1rem; }
  .vettingBox h2.ui.header,
  .ui.segment.reqBox h2.ui.header {
    font-size: 1.4rem; }

.vettingFormBox {
  max-width: 50%; }

.gridTitle {
  background: #555; }
  .gridTitle .ui.header {
    font-size: 1.8rem; }

.gridHeader {
  color: #b4b4b4 !important; }

.ui.card,
.ui.cards > .card,
.ui.card {
  background-color: #2b2b2b;
  box-shadow: 0 1px 5px 0 rgba(16, 16, 16, 0.33);
  border: 0; }
  .ui.card .content,
  .ui.cards > .card .content,
  .ui.card .content {
    background: #3e3d3d; }
    .ui.card .content .header,
    .ui.cards > .card .content .header,
    .ui.card .content .header {
      color: white !important; }
    .ui.card .content .meta,
    .ui.cards > .card .content .meta,
    .ui.card .content .meta {
      color: #b4b4b4 !important; }

.ui.card > .extra,
.ui.cards > .card > .extra {
  background: #222; }

.vehicleBox {
  background: #555; }
  .vehicleBox .header {
    text-align: center !important; }

.userRoles .ui.label {
  margin: 0.2rem; }

.ui.grid .column > .columnHeader,
.ui.grid .column.columnHeader {
  display: flex;
  justify-content: space-between; }

.ui.grid .gridHeaderRow {
  background: #555; }
  .ui.grid .gridHeaderRow .column {
    font-size: 1.1rem;
    padding: 0.5rem !important; }

.ui.grid .selectableRow:hover {
  background: #3c3c3c;
  cursor: pointer; }

.userContent .segment.tab {
  padding: 0; }

.ui.button.compButtons {
  color: #ccc !important;
  font-size: 1.2rem;
  box-shadow: 0 0 0 1px rgba(223, 222, 222, 0.5) inset; }

.ui.buttons > .ui.button.compButtons {
  color: #ccc !important;
  font-size: 1rem;
  box-shadow: 0 0 0 1px rgba(223, 222, 222, 0.5) inset; }

.ui.basic.inverted.button.compButtons.notComp {
  box-shadow: 0 0 0 1px #e90707 inset !important; }

.ui.button.compButtons.comp {
  box-shadow: 0 0 0 1px rgba(5, 226, 60, 0.466) inset !important; }

.ui.celled.grid {
  width: 100%;
  margin: 1em 0;
  box-shadow: 0 0 0 1px #222; }
  .ui.celled.grid > .row {
    box-shadow: 0 -1px 0 0 #222; }
    .ui.celled.grid > .row > .column {
      box-shadow: -2px 0 0 0 #222; }

.module {
  border-bottom: 2px solid #222 !important;
  border-top: 4px solid #222 !important; }
  .module .ui.header {
    background: #333;
    padding: 0.6rem 1rem !important;
    border-bottom: 1px solid #222;
    margin: 0 !important;
    justify-content: space-between;
    display: flex; }
    .module .ui.header span {
      float: right;
      font-size: small;
      font-variant: small-caps;
      color: #a8a7a7; }
  .module .row {
    padding: 0 !important; }
  .module .column {
    padding: 0 !important; }
  .module .stat {
    padding-right: 2rem;
    padding-left: 2rem; }
  .module .ui.button {
    border-radius: 0 !important; }

.rolesList .ui.button {
  margin: 0.5rem 0;
  font-size: large;
  padding: 0.6rem; }

.statBox .statistic {
  margin: 0 2rem;
  padding-right: 1rem; }

.ui.grid.dataGrid .column {
  padding-left: 0 !important;
  padding-right: 0 !important; }
  .ui.grid.dataGrid .column .ui.header {
    background: #222; }

.ui.basic.icon.button.subtleButton:hover {
  background-color: #333 !important; }
